import './App.less';
import React from 'react';
import 'antd/dist/antd.less';
import LeftSider from "./components/LeftSider";
import RightSider from "./components/RightSider";
import Content from "./components/Content";
import Information from "./components/Information";
import Header from "./components/Header";
import Consult from "./components/Consult";
import Sources from "./components/Sources";
import Imprint from "./components/Imprint";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: 0
        }
    }

    render() {
        // Routing
        switch (this.state.content) {
            // Informationen
            case 1:
                return (
                    <div className="layout">
                        <Header onHeaderClick={() => this.setState({ content: 0})}/>
                        <div className={"contentLayout"}>
                            <LeftSider
                                onSourceClick={() => this.setState({ content: 3 })}
                                onInfoClick={() => this.setState({ content: 1 })}
                                onConsultClick={() => this.setState({ content: 2 })}
                                onImprintClick={() => this.setState({content: 4})}/>
                            <Information />
                            <RightSider />
                        </div>
                    </div>
                )
            // Beratungsstellen
            case 2:
                return (
                    <div className="layout">
                        <Header onHeaderClick={() => this.setState({ content: 0 })}/>
                        <div className={"contentLayout"}>
                            <LeftSider
                                onSourceClick={() => this.setState({ content: 3 })}
                                onInfoClick={() => this.setState({ content: 1 })}
                                onConsultClick={() => this.setState({ content: 2 })}
                                onImprintClick={() => this.setState({content: 4})}/>
                            <Consult />
                            <RightSider />
                        </div>
                    </div>
                )
            // Quellen
            case 3:
                return (
                    <div className="layout">
                        <Header onHeaderClick={() => this.setState({ content: 0 })}/>
                        <div className={"contentLayout"}>
                            <LeftSider
                                onSourceClick={() => this.setState({ content: 3 })}
                                onInfoClick={() => this.setState({ content: 1 })}
                                onConsultClick={() => this.setState({ content: 2 })}
                                onImprintClick={() => this.setState({content: 4})}/>
                            <Sources />
                            <RightSider />
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="layout">
                        <Header onHeaderClick={() => this.setState({ content: 0 })}/>
                        <div className={"contentLayout"}>
                            <LeftSider
                                onSourceClick={() => this.setState({ content: 3 })}
                                onInfoClick={() => this.setState({ content: 1 })}
                                onConsultClick={() => this.setState({ content: 2 })}
                                onImprintClick={() => this.setState({content: 4})}/>
                            <Imprint />
                            <RightSider />
                        </div>
                    </div>
                )
            // Performance
            default:
                return (
                    <div className="layout">
                        <Header onHeaderClick={() => this.setState({ content: 0 })}/>
                        <div className={"contentLayout"}>
                            <LeftSider
                                onSourceClick={() => this.setState({ content: 3 })}
                                onInfoClick={() => this.setState({ content: 1 })}
                                onConsultClick={() => this.setState({ content: 2 })}
                                onImprintClick={() => this.setState({content: 4})}/>
                            <Content />
                            <RightSider />
                        </div>
                    </div>
                )
        }

    }
}

export default App;
