import React from 'react';
import './Information.less'


const Information = () => (

    <div className={"title"}>

            <h2 style={{color: 'black'}}>Informationen</h2>
            <p>
                Diese Website ist Teil eines Bachelorprojekts im Bereich Szenografie.
                Als praktischer Teil der Arbeit wurde eine Video-Performance zum Thema Partnerschaftsgewalt
                konzipiert. Die Website dient als Plattform für diese Performance und die damit verknüpfte
                Informationsvermittlung.
            </p>
            <p>
                Die hier aufgelisteten Informationen erheben keinen Anspruch auf Vollständigkeit und bilden nur
                einen kleinen Teil des Themas ab.
            </p>
            <p>
                Auf den Fotos in der Videoperformance sind computergenerierte Gesichter abgebildet, diese Personen
                existieren also nicht. Auch die Fälle sind konstruiert, können so oder so ähnlich jedoch passiert
                sein. Als Inspiration dienten die fiktiven Fälle aus Christina Clemms Buch „AktenEinsicht:
                Geschichten von Frauen und Gewalt.“.
            </p>
            <p>
                Das Projekt beschäftigt sich mit Gewalt gegen Frauen, daher werden keine Informationen über Gewalt
                gegen Männer oder Kinder dargestellt und bei eigenen Texten meist von Täter gesprochen. Die
                jeweiligen Paragrafen sind im Wortlaut aus dem Strafgesetzbuch übernommen, so auch die dort
                verwendeten Personenbezeichnungen. Statistiken zu Partnerschaftsgewalt gliedern sich meist nur in
                „männlich“ und „weiblich“, weshalb im Kontext dieses Projekts auch nur die Daten von weiblich
                gelesenen Personen berücksichtigt werden. Es können aber alle Menschen, unabhängig von ihrer
                Geschlechtsidentität, von Gewalt betroffen sein oder diese ausüben.
            </p>
            <p>
                Die hier genannten Daten beziehen sich nur auf Deutschland.
            </p>

    </div>
)

export default Information;