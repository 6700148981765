import React from "react";
import { Typography } from 'antd';
import './Header.less'

const { Title } = Typography;

class Header extends React.Component {
    render() {
        return(
            <div onClick={this.props.onHeaderClick} style={{cursor: 'pointer'}}>
                <Typography className={"header"}>
                    <Title level={0} className={"heading"}>this person does exist</Title>
                </Typography>
            </div>
        )
    }
}

export default Header