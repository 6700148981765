import React from 'react';
import { Typography } from 'antd';
import './Information.less'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const { Link } = Typography;

const Sources = () => (
    <div className={"title"}>
        <h2>Quellen</h2>
        <SimpleBar style={{ height: '90%' }}>
            <h3>Gemeinsame Informationen</h3>
            <p>
                BMFSFJ:
                <Link href={"https://www.bmjv.de/SharedDocs/Publikationen/DE/Schutz_haeusliche_Gewalt.pdf?__blob=publicationFile&v=22"}>
                    Mehr Schutz bei häuslicher Gewalt, Informationen zum Gewaltschutzgesetz
                </Link>, Zugriff: 05.02.2021<br />
                Bundeskanzleramt, Sektion Familie und Jugend:
                <Link href={"https://www.gewaltinfo.at/fachwissen/formen/psychisch/"}>
                    Psychische Gewalt
                </Link>, Zugriff: 05.02.2021<br />
                Christina Clemm: Akteneinsicht: Geschichten von Frauen und Gewalt, München, 2020<br />
                WEISSER RING:
                <Link href={"https://weisser-ring.de/system/files/domains/weisser_ring_dev/downloads/daten-zahlen-faktenwr.pdf"}>
                    Häusliche Gewalt in Zahlen
                </Link>, Zugriff: 05.02.2021
            </p>
            <h3>Laura B.</h3>
            <p>
                Bundesrepublik Deutschland (Hrsg.), Bundesamt für Justiz:
                <Link href={"https://www.gesetze-im-internet.de/stgb/__238.html"}>
                    Stafgesetzbuch, Achtzehnter Abschnitt, Straftaten gegen die persönliche Freiheit, § 238 Nachstellung
                </Link>, Zugriff: 05.02.2021<br />
                Christina Clemm: Akteneinsicht: Geschichten von Frauen und Gewalt, München, 2020<br />
                Landesverband Frauenberatung Schleswig-Holstein e.V. (Hrsg.): Was tun bei Stalking?, Kiel 2019<br />
                Statista:
                <Link href={"https://de.statista.com/statistik/daten/studie/157327/umfrage/polizeilich-erfasste-faelle-von-stalking-seit-2007/"}>
                    Anzahl der polizeilich erfassten Fälle von Stalking in Deutschland von 2008 bis 2019
                </Link>, Zugriff: 05.02.2021
            </p>

            <h3>Ayleen H.</h3>
            <p>
                Bundeskriminalamt (Hrsg.), Partnerschaftsgewalt, Kriminalistische Auswertung - Berichtsjahr 2019, Wiesbaden 2020<br />
                Bundesrepublik Deutschland (Hrsg.), Bundesamt für Justiz:
                <Link href={"https://www.gesetze-im-internet.de/stgb/__223.html"}>
                    Stafgesetzbuch, Siebzehnter Abschnitt, Straftaten gegen die körperliche Unversehrtheit, § 223 Körperverletzung
                </Link>, Zugriff: 05.02.2021<br />
                Christina Clemm: Akteneinsicht: Geschichten von Frauen und Gewalt, München, 2020
            </p>

            <h3>Birgit M.</h3>
            <p>
                Bundesrepublik Deutschland (Hrsg.), Bundesamt für Justiz:
                <Link href={"https://www.gesetze-im-internet.de/stgb/__211.html"}>
                    Sechzehnter Abschnitt, Straftaten gegen das Leben, § 211 Mord
                </Link>, Zugriff: 05.02.2021<br />
                Bundesrepublik Deutschland (Hrsg.), Bundesamt für Justiz:
                <Link href={"https://www.gesetze-im-internet.de/stgb/__212.html"}>
                    Sechzehnter Abschnitt, Straftaten gegen das Leben, § 212 Totschlag
                </Link>, Zugriff: 05.02.2021<br />
                Christina Clemm: Akteneinsicht: Geschichten von Frauen und Gewalt, München, 2020<br />
                Emundts, Corinna:
                <Link href={"https://www.tagesschau.de/inland/beziehungsgewalt-bka-statistik-101.html"}>
                    „Die Zahlen sind schockierend“
                </Link>, Zugriff: 05.02.2021<br />
                openJur:
                <Link href={"https://openjur.de/u/339352.html"}>
                    BGH, Urteil vom 01.03.2012 - 3 StR 425/11
                </Link>, Zugriff: 05.02.2021
            </p>

            <h3>Maria E.</h3>
            <p>
                Bundeskriminalamt (Hrsg.), Partnerschaftsgewalt, Kriminalistische Auswertung - Berichtsjahr 2019, Wiesbaden 2020<br />
                Bundesrepublik Deutschland (Hrsg.), Bundesamt für Justiz:
                <Link href={"https://www.gesetze-im-internet.de/stgb/__212.html"}>
                    Stafgesetzbuch, Dreizehnter Abschnitt, Straftaten gegen die sexuelle Selbstbestimmung, § 177 Sexueller Übergriff; sexuelle Nötigung, Vergewaltigung
                </Link>, Zugriff: 05.02.2021<br />
                Christina Clemm: Akteneinsicht: Geschichten von Frauen und Gewalt, München, 2020<br />
                Deutscher Bundestag, Wissenschaftliche Dienste:
                <Link href={"https://www.fes.de/index.php?eID=dumpFile&t=f&f=35597&token=2314f30c6efee6df0df980acb413f5fc7e1205b2"}>
                    Vergewaltigung in der Ehe, Strafrechtliche Beurteilung im europäischen Vergleich
                </Link>, Zugriff: 05.02.2021<br />
                Fiebig, Peggy:
                <Link href={"https://www.deutschlandfunk.de/das-neue-sexualstrafrecht-nein-heisst-nein.724.de.html?dram:article_id=400280"}>
                    „Nein heißt Nein“
                </Link>, Zugriff: 05.02.2021<br />
                NWB Verlag GmbH & Co. KG (Hrsg.):
                <Link href={"https://datenbank.nwb.de/Dokument/Anzeigen/345858/"}>
                    BGH Beschluss v. 16.06.2009 - 4 StR 647/08
                </Link>, Zugriff: 05.02.2021<br />
                openJur:
                <Link href={"https://openjur.de/u/270402.html"}>
                    BGH, Urteil vom 02.11.1966 - IV ZR 239/65
                </Link>, Zugriff: 05.02.2021<br />
                Süddeutsche Zeitung:
                <Link href={"https://www.sueddeutsche.de/panorama/frauen-in-afghanistan-sexpflicht-im-gesetz-1.396189"}>
                    Sexpflicht im Gesetz
                </Link>, Zugriff: 05.02.2021
            </p>
            <h3>Bilderquelle</h3>
            <p>
                Karras, Tero /NVIDIA, et al.:
                <Link href={"https://www.thispersondoesnotexist.com"}>
                    This Person Does Not Exist
                </Link>, Zugriff: 24.01.2021
            </p>
        </SimpleBar>
    </div>

)

export default Sources;