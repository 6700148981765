import React from 'react';
import { Typography } from 'antd';
import './Consult.less'
import { Card, Col, Row } from 'antd';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const { Link } = Typography;

const Consult = () => (
    <div className={"consultWrapper"}>
        <h2>Beratungsstellen</h2>
        <p>Für die Inhalte externer Links wird keine Haftung übernommen.</p>
        <SimpleBar style={{ height: '90%', overflowX: 'hidden' }}>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>Hilfetelefon</span>} bordered={true} hoverable={true}>
                        <Link href={"https://www.hilfetelefon.de"}>www.hilfetelefon.de</Link>
                        <p>08000 - 116 016</p>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>Hilfetelefon Sexueller Missbrauch</span>} bordered={true} hoverable={true}>
                        <Link href={"https://www.hilfeportal-missbrauch.de"}>www.hilfeportal-missbrauch.de</Link>
                        <p>0800 - 22 55 530</p>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>WEISSER RING Opfer-Telefon</span>} bordered={true} hoverable={true}>
                        <Link href={"https://www.weisser-ring.de"}>www.weisser-ring.de</Link>
                        <p>0800 - 116 006</p>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>TelefonSeelsorge</span>} bordered={true} hoverable={true}>
                        <Link href={"https://www.telefonseelsorge.de"}>www.telefonseelsorge.de</Link>
                        <p>0800 - 111 011 1</p>
                        <p>0800 - 111 022 2</p>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>Bundesverband Frauenberatungsstellen und Frauennotrufe</span>} bordered={true} hoverable={true}>
                        <p>Frauen gegen Gewalt e.V.</p>
                        <Link href={"https://www.frauen-gegen-gewalt.de"}>www.frauen-gegen-gewalt.de</Link>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>Frauenhaus-Koordinierung e.V.</span>} bordered={true} hoverable={true}>
                        <Link href={"https://www.frauenhauskoordinierung.de"}>www.frauenhauskoordinierung.de</Link>
                        <p>030 – 338 43 42 0</p>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>Maneo </span>} bordered={true} hoverable={true}>
                        <p>Das schwule Anti-Gewalt-Projekt in Berlin</p>
                        <Link href={"https://www.maneo.de"}>www.maneo.de</Link>
                        <p>030 - 216 33 36</p>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify={"center"} className={"cardRow"}>
                <Col span={16}>
                    <Card title={<span style={{color: 'black'}}>gewaltfreileben</span>} bordered={true} hoverable={true}>
                        <p>Beratungsstelle für Lesben, Trans* und queere Menschen</p>
                        <Link href={"https://www.gewaltfreileben.org"}>www.gewaltfreileben.org</Link>
                        <p>069 - 43 00 52 33</p>
                    </Card>
                </Col>
            </Row>

        </SimpleBar>
    </div>
)

export default Consult;