import React from 'react';
import './RightSider.less'

const RightSider = () => (
    <div className={"rightSider"}>
        <div className={"copyright"}>
            <p>© alexandra rauschgold 2021</p>
        </div>
    </div>
)

export default RightSider;