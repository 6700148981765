import React from 'react';
import './Content.less'
import {Modal} from "antd";
import CustomButton from "./CustomButton";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


const INITIAL_STATE = {
    visible: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    buttonVisibility: 'hidden',
    currentTime: 0
}


class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.vidWrapperRef = React.createRef();
        this.interval = null;
        this.vidRef = React.createRef();
    }

    componentDidMount() {
        this.vidWrapperRef.current.style.top = '50%';

    }

    updateButtons = () => {
        if(this.vidRef.current.currentTime >= 479) {
            this.setState({ buttonVisibility: 'visible'});
        } else {
            this.setState({ buttonVisibility: 'hidden'});
        }
    }

    moveUp = () => {
        clearInterval(this.interval)
        this.interval = setInterval(this.frameUp, 5);
   }

   frameUp = () => {
        let posString = this.vidWrapperRef.current.style.top;
        let pos = parseInt(posString, 10);
        let target = 50 - (69.9 / window.innerHeight) * 100
       console.log(target);
        if(pos <= 40) {
           clearInterval(this.interval);
        } else {
           pos = pos - 1;
           this.vidWrapperRef.current.style.top = pos + '%';
        }
   }

   frameDown = () => {
       let posString = this.vidWrapperRef.current.style.top;
       let pos = parseInt(posString, 10);
       if(pos === 50) {
           clearInterval(this.interval);
       } else {
           pos = pos + 1;
           this.vidWrapperRef.current.style.top = pos + '%';
       }
   }

   moveDown = () => {
       clearInterval(this.interval)
       this.interval = setInterval(this.frameDown, 5);
   }

    // shows a modal with certain id, makes others invisable
    showModal = (number) => {
        let newVisible = this.state.visible.slice();
        newVisible = newVisible.map( (item) => item = false);
        newVisible[number] = true;
        this.setState({visible: newVisible});
        this.moveUp();
    }

    // clears all modals
    clearModal = () => {
        let newVisible = this.state.visible.slice();
        newVisible = newVisible.map( (item) => item = false);
        this.setState({visible: newVisible});
        this.moveDown();
    }

    render() {
        let intViewportHeight = window.innerHeight;
        let modalHeight = intViewportHeight * 0.12 + 'px';
        return(
            <div className={"content"}>
                <div className="videoWrapper" id={"vid"} ref={this.vidWrapperRef}>
                    <video
                        width={"100%"}
                        controls
                        controlsList={"nodownload"}
                        id={"vid"}
                        onTimeUpdate={this.updateButtons}
                        ref={this.vidRef}>
                        <source src={"/video/ThisPersonDoesExist.mp4"} type={"video/mp4"}/>
                    </video>

                    <CustomButton onClick={() => this.showModal(0)} className={"button0"} visibility={this.state.buttonVisibility}/>

                    <CustomButton onClick={() => this.showModal(2)} className={"button2"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(3)} className={"button3"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(4)} className={"button4"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(5)} className={"button5"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(6)} className={"button6"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(7)} className={"button7"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(8)} className={"button8"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(9)} className={"button9"}  visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(10)} className={"button10"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(11)} className={"button11"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(12)} className={"button12"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(13)} className={"button13"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(14)} className={"button14"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(15)} className={"button15"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(16)} className={"button16"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(17)} className={"button17"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(18)} className={"button18"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(19)} className={"button19"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(20)} className={"button20"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(21)} className={"button21"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(22)} className={"button22"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(23)} className={"button23"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(24)} className={"button24"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(25)} className={"button25"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(26)} className={"button26"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(27)} className={"button27"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(28)} className={"button28"} visibility={this.state.buttonVisibility}/>
                    <CustomButton onClick={() => this.showModal(29)} className={"button29"} visibility={this.state.buttonVisibility}/>
                </div>

                {/* Paragraph 238, Button 0 */}
                <Modal
                    visible={this.state.visible[0]}
                    title={<div><h1>Laura B.</h1><h3>§ 238 StGB Nachstellung</h3></div>}
                    onCancel={this.clearModal}
                    mask={false}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <dl>
                            <dt>(1)</dt>
                            <dd>Mit Freiheitsstrafe bis zu drei Jahren oder mit Geldstrafe wird bestraft, wer
                                einer anderen Person in einer Weise unbefugt nachstellt, die geeignet ist, deren
                                Lebensgestaltung schwerwiegend zu beeinträchtigen, indem er beharrlich
                            </dd>
                            <ol>
                                <li>die räumliche Nähe dieser Person aufsucht,</li>
                                <li>unter Verwendung von Telekommunikationsmitteln oder sonstigen Mitteln der
                                    Kommunikation oder über Dritte Kontakt zu dieser Person herzustellen versucht,
                                </li>
                                <li>unter missbräuchlicher Verwendung von personenbezogenen Daten dieser Person
                                    <dl>
                                        <dt>a)</dt>
                                        <dd>Bestellungen von Waren oder Dienstleistungen für sie aufgibt oder</dd>
                                        <dt>b)</dt>
                                        <dd>Dritte veranlasst, Kontakt mit ihr aufzunehmen, oder</dd>
                                    </dl>
                                </li>
                                <li>diese Person mit der Verletzung von Leben, körperlicher Unversehrtheit,
                                    Gesundheit oder Freiheit ihrer selbst, eines ihrer Angehörigen oder einer anderen
                                    ihr nahestehenden Person bedroht oder
                                </li>
                                <li>eine andere vergleichbare Handlung vornimmt.</li>
                            </ol>
                            <dt>(2)</dt>
                            <dd>Auf Freiheitsstrafe von drei Monaten bis zu fünf Jahren ist zu erkennen, wenn
                                der Täter das Opfer, einen Angehörigen des Opfers oder eine andere dem Opfer nahe
                                stehende Person durch die Tat in die Gefahr des Todes oder einer schweren
                                Gesundheitsschädigung bringt.
                            </dd>
                            <dt>(3)</dt>
                            <dd>Verursacht der Täter durch die Tat den Tod des Opfers, eines Angehörigen des
                                Opfers oder einer anderen dem Opfer nahe stehenden Person, so ist die Strafe
                                Freiheitsstrafe von einem Jahr bis zu zehn Jahren.
                            </dd>
                            <dt>(4)</dt>
                            <dd>In den Fällen des Absatzes 1 wird die Tat nur auf Antrag verfolgt, es sei denn,
                                dass die Strafverfolgungsbehörde wegen des besonderen öffentlichen Interesses an der
                                Strafverfolgung ein Einschreiten von Amts wegen für geboten hält.
                            </dd>
                        </dl>
                    </SimpleBar>
                </Modal>

                {/* Ayleen Bild, Button 1*/}
                <Modal
                    visible={this.state.visible[2]}
                    title={<h1>Ayleen H.</h1>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>Körperverletzung durch den Partner in Geflüchtetenunterkunft</p>
                    </SimpleBar>
                </Modal>

                {/*Paragraph 211, 212*/}
                <Modal
                    visible={this.state.visible[3]}
                    title={<div><h1>Birgit M.</h1><h3>§ 211 StGB Mord, § 212 StGB Totschlag</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight }}>
                        <dl>
                            <dt>(1)</dt>
                            <dd>Der Mörder wird mit lebenslanger Freiheitsstrafe bestraft.</dd>
                            <dt>(2)</dt>
                            <dd>Mörder ist, wer aus Mordlust, zur Befriedigung des Geschlechtstriebs, aus
                                Habgier oder sonst aus niedrigen Beweggründen, heimtückisch oder grausam oder mit
                                gemeingefährlichen Mitteln oder um eine andere Straftat zu ermöglichen oder zu
                                verdecken, einen Menschen tötet.
                            </dd>
                        </dl>
                        <dt>(1)</dt>
                        <dd>Wer einen Menschen tötet, ohne Mörder zu sein, wird als Totschläger mit
                            Freiheitsstrafe nicht unter fünf Jahren bestraft.
                        </dd>
                        <dt>(2)</dt>
                        <dd>In besonders schweren Fällen ist auf lebenslange Freiheitsstrafe zu erkennen
                        </dd>
                    </SimpleBar>
                </Modal>

                {/* Cyberstalking */}
                <Modal
                    visible={this.state.visible[4]}
                    title={<div><h1>Laura B.</h1><h3>Cyberstalking</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight }}>
                        <p>
                            Vor allem durch die Nutzung von sozialen Medien hat sich eine Sonderform des
                            Stalkings stark verbreitet: Cyberstalking. <br/>Unter Cyberstalking fällt unter anderem
                        </p>
                        <ul>
                            <li>das permanente Kontaktieren im Internet</li>
                            <li>
                                die Veröffentlichung von Fotos oder privaten Informationen einer Person gegen deren
                                Willen
                            </li>
                            <li>
                                die Erstellung von Accounts unter dem Namen der betroffenen Person (Identitätsdiebstahl)
                            </li>
                            <li>das Verschaffen von Zugangsdaten für Internet-Accounts</li>
                            <li>
                                das Ausspionieren von u.a. Gesprächen oder des Standortes der betroffenen Person mittels
                                Spionagesoftware
                            </li>
                        </ul>
                        <p>
                            Die besondere Problematik beim Cyberstalking ist die Anonymität im Internet. Dadurch sinkt
                            die Hemmschwelle der Täter*innen. Auch können sie mit wenigen Klicks in kürzester Zeit viele
                            Menschen aus dem Umfeld der betroffenen Person erreichen.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Pflicht zur Geschlechtsgemeinschaft */}
                <Modal
                    visible={this.state.visible[7]}
                    title={<div><h1>Maria E.</h1><h3>„Pflicht zur Geschlechtsgemeinschaft“ und deren „ständiger Wiederholung“</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>Im Urteil eines Scheidungsverfahrens von 1966 hieß es folgendes:</p>
                        <p>
                            „Die Frau genügt ihren ehelichen Pflichten nicht schon damit, dass sie die Beiwohnung
                            teilnahmslos geschehen lässt. Wenn es ihr infolge ihrer Veranlagung oder aus anderen
                            Gründen, zu denen Unwissenheit der Eheleute gehören kann, versagt bleibt, im ehelichen
                            Verkehr Befriedigung zu finden, so fordert die Ehe von ihr doch, eine Gewährung in ehelicher
                            Zuneigung und Opferbereitschaft und verbietet es, Gleichgültigkeit oder Widerwillen zur
                            Schau zu tragen. Denn erfahrungsgemäß vermag sich der Partner, der im ehelichen Verkehr
                            seine natürliche und legitime Befriedigung sucht, auf die Dauer kaum jemals mit der bloßen
                            Triebstellung zu begnügen, ohne davon berührt zu werden, was der andere dabei empfindet.“
                        </p>
                        <p>
                            Diesem nicht mal 60 Jahre alten Urteil nach, war es die Pflicht der Ehefrau
                            Geschlechtsverkehr mit ihrem Partner zu haben, ob sie wollte oder nicht und egal, ob sie
                            dabei Spaß hatte oder nicht. Sollte sie keinen Spaß bzw. keine Befriedigung dabei verspüren,
                            durfte sie dies nicht zeigen und musste ein Gefallen vortäuschen.
                        </p>
                        <p>
                            Erst seit 1977 ist die Vergewaltigung in der Ehe strafbar. In diesem Jahr wurde die
                            Einschränkung „außerehelich“ aus § 177 des Strafgesetzbuchs gestrichen, wodurch auch die
                            eheliche Vergewaltigung zur Straftat wurde.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Urteilsgrungslage */}
                <Modal
                    visible={this.state.visible[5]}
                    title={<div><h1>Birgit M.</h1><h3>Urteilsgrundlage</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ height: modalHeight }}>
                        <p>
                            Tod durch den Partner oder Expartner wird häufig nicht als Mord oder Totschlag gewertet,
                            sondern als Körperverletzung mit Todesfolge.
                        </p>
                        <p>
                            Grund dafür ist, dass bei vorangegangener schwerer Gewalt nicht zweifelsfrei festgestellt
                            werden kann, ob der Mann die Frau dieses Mal wirklich umbringen wollte. In solchen Fällen ist
                            kein deutlicher Vorsatz festzustellen.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Vergewaltigungsmythen */}
                <Modal
                    visible={this.state.visible[6]}
                    title={<div><h1>Maria E.</h1><h3>Vergewaltigungsmythen</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Hartnäckig halten sich bestimmte Vorstellungen, wie eine Vergewaltigung aussieht oder wie
                            sich ein Vergewaltigungsopfer zu verhalten hat.
                        </p>
                        <p>
                            Frauen, die Vergewaltigungen anzeigen, wird häufig unterstellt, dass sie sich dadurch einen
                            Vorteil verschaffen wollen. Sei es das alleinige Sorgerecht für gemeinsame Kinder, ein
                            Umgangsverbot für den Vater, eine Schädigung des Vorgesetzten oder finanzieller Vorteil.
                        </p>
                        <p>
                            Tatsächlich ist es so, dass ein Gerichtsverfahren neben hohen psychischen Belastungen auch
                            finanzielle Nachteile für die anzeigende Frau haben kann. Ist der Mann zum Beispiel der
                            alleinige Verdiener gewesen und kommt ins Gefängnis, bedeutet dies auch eine völlig neue und
                            vermutlich schlechtere finanzielle Situation für die Frau. Handelt es sich beim Täter um
                            einen Vorgesetzten, der ein hohes Ansehen genießt, kann die Anzeige auch negativ auf die
                            Frau zurückfallen. Spricht sich die Anzeige in der Branche rum, ist nicht der Ruf des
                            Vorgesetzten, sondern ihrer ruiniert und sie kann mit einer schwierigen Jobsuche
                            konfrontiert werden.
                        </p>
                        <p>
                            Die Schmerzensgeldzahlungen haben in Deutschland ein niedriges Niveau. Wenn die Zahlungen
                            überhaupt eintreibbar sind, kommen sie kaum für die entstandenen Folgen auf.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Strafmilderung */}
                <Modal
                    visible={this.state.visible[11]}
                    title={<div><h1>Maria E.</h1><h3>Strafmilderung</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Am 16.07.2009 hat das Landgericht in Dessau beschlossen, dass das Urteil einer
                            Vergewaltigung strafmildernd ausfällt, da die betroffene Frau in der Nacht zuvor
                            „[...]offensiv und auch mit ihren körperlichen Attributen kokettierend zugegangen war und
                            mit [dem Täter] einverständlich den Geschlechtsverkehr ausgeführt habe.“
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Birgit M */}
                <Modal
                    visible={this.state.visible[8]}
                    title={<h1>Birgit M.</h1>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>Tod durch den Expartner</p>
                    </SimpleBar>
                </Modal>

                {/* Dunkelziffer */}
                <Modal
                    visible={this.state.visible[10]}
                    title={<div><h1>Laura B., Ayleen H., Birgit M., Maria E.</h1><h3>Dunkelziffer</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Statistisch lassen sich nur die Fälle erfassen, die auch gemeldet werden. Es werden aber nur
                            ein Bruchteil der Fälle von Partnerschaftsgewalt angezeigt. Es wird davon ausgegangen, dass
                            mindestens 80% der Fälle nicht erfasst werden, das Dunkelfeld ist daher sehr groß.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Risiko für Partnerschaftsgewalt */}
                <Modal
                    visible={this.state.visible[9]}
                    title={<div><h1>Laura B., Ayleen H., Birgit M., Maria E.</h1><h3>Risiko für Partnerschaftsgewalt</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Es gibt Abschnitte im Leben, in denen das Risiko für Partnerschaftsgewalt besonders hoch
                            ist: Kurz nach der Hochzeit, während der Schwangerschaft, wenn das Kind noch unter einem
                            Jahr alt ist oder wenn die Betroffene kommuniziert, dass sie sich trennen möchte.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Nicht jede Gewalt ist psychisch */}
                <Modal
                    visible={this.state.visible[13]}
                    title={<div><h1>Laura B., Ayleen H., Birgit M., Maria E.</h1><h3>Nicht jede Gewalt ist physisch</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Neben Körperverletzung gibt es auch verschiedene Formen psychischer Gewalt:
                        </p>
                        <p>
                            Soziale Gewalt: Zum Beispiel durch das Isolieren einer Person aus ihrem sozialen Umfeld
                            (Familie, Freund*innen)
                        </p>
                        <p>
                            Drohungen, Nötigung und Angstmachen:
                            Zum Beispiel durch die Androhung, Familienmitglieder oder das Haustier zu verletzen.
                            Durch die dadurch ausgelöste Angst und Einschüchterung „erübrigt“ sich häufig die
                            Anwendung physischer Gewalt.
                        </p>
                        <p>
                            Beschimpfungen, Abwertungen und Diffamierungen:
                            Sie zielen darauf ab, das Selbstwertgefühl und die mentale Gesundheit der betroffenen
                            Person zu destabilisieren.
                        </p>
                        <p>
                            Belästigung und Terror (Stalking):
                            Zum Beispiel in Form von ständigen Anrufen zu jeder Tageszeit, Drohbriefen und Verfolgung.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Gewaltschutzgesetz */}
                <Modal
                    visible={this.state.visible[27]}
                    title={<div><h1>Laura B., Ayleen H., Birgit M., Maria E.</h1><h3>Gewaltschutzgesetz</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Durch das Gewaltschutzgesetz von 2002 wird den von Gewalt betroffenen Personen ermöglicht,
                            dem Täter zu untersagen, Kontakt zu ihnen aufzunehmen oder sich ihnen zu nähern. Durch
                            dieses Gesetz wird den Betroffenen zusätzlich ermöglicht, weiterhin die gemeinsame Wohnung,
                            ohne den/die Täter*in, nutzen zu können.
                        </p>
                        <p>
                            Problematisch dabei ist jedoch, dass die meisten Familiengerichte oder Strafjustizen keine
                            Ressourcen haben, Verstöße gegen die Unterlassung konsequent zu verfolgen.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Nein heißt nein */}
                <Modal
                    visible={this.state.visible[12]}
                    title={<div><h1>Maria E.</h1><h3>„Nein-heißt-Nein-Regelung“</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Am 10.11.2016 wurde ein neues Sexualstrafrecht verabschiedet. Dieses legt fest, dass ein
                            Sexualdelikt dann gegeben ist, wenn die Handlung ohne erkennbaren Willen einer anderen
                            Person geschieht.
                        </p>
                        <p>
                            Vor dieser Änderung galt als Sexualdelikt nur eine Handlung, bei der sich eine andere Person
                            körperlich gewehrt hat und der Person Gewalt angedroht oder angetan wurde.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Fälle von Körperverletzung */}
                <Modal
                    visible={this.state.visible[28]}
                    title={<div><h1>Maria E.</h1><h3>Fälle von Körperverletzung innerhalb der Partnerschaft in 2019:</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Schwere Körperverletzung: 57
                        </p>
                        <p>
                            Gefährliche Körperverletzung:  11.991
                        </p>
                        <p>
                            Vorsätzliche einfache Körperverletzung: 69.012
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Laura B. */}
                <Modal
                    visible={this.state.visible[14]}
                    title={<div><h1>Laura B.</h1></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>Stalking durch den Expartner</p>
                    </SimpleBar>
                </Modal>

                {/* Täter Opfer Umkehr */}
                <Modal
                    visible={this.state.visible[17]}
                    title={<div><h1>Maria E.</h1><h3>Täter-Opfer-Umkehr</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Frauen wird häufig eine Mitschuld an der Tat gegeben („Victim Blaming“ oder auch
                            „Opferbeschuldigung“). Oft wird in solchen Fällen auf die Kleidung verwiesen. Aber auch das
                            Verhalten oder ein möglicher Drogenkonsum werden oft als Gründe für eine Strafmilderung des
                            Angeklagten genutzt.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Anzeigeverhalten */}
                <Modal
                    visible={this.state.visible[15]}
                    title={<div><h1>Ayleen H.</h1><h3>Anzeigeverhalten</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Viele Betroffene zeigen einen Übergriff nicht an. Dies kann verschiedene Gründe haben. Bei
                            Betroffenen mit Migrationshintergrund hat vor allem ihr Status Einfluss darauf, ob sie eine
                            Tat anzeigen oder nicht. Sie haben Angst vor der Abschiebung oder davor, die Kinder zu
                            verlieren. Hinzu kommt, dass einige nicht möchten, dass dem Partner oder Expartner
                            strafrechtliche oder aufenthaltsrechtliche Konsequenzen drohen. Ein anderer Grund kann auch
                            sein, dass die Betroffene lieber gemeinsam mit ihrem gewalttätigen Mann in einem fremden und
                            oft feindseligen Land ist, als dort alleine als Frau zu leben.
                        </p>
                        <p>
                            Den Betroffenen fehlen aber auch häufig die nötigen Informationen, da es in
                            Geflüchtetenunterkünften wenig bis gar keine Unterstützung für Betroffene von Gewalt oder
                            Übersetzer*innen gibt.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Anzahl Fälle von Vergewaltigungen */}
                <Modal
                    visible={this.state.visible[16]}
                    title={<div><h1>Maria E.</h1> <h3>
                        Anzahl der Fälle von Vergewaltigung, sexueller Nötigung und sexuellen
                        Übergriffen innerhalb der Partnerschaft in 2019:
                    </h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            3027
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Migrationshintergrund */}
                <Modal
                    visible={this.state.visible[19]}
                    title={<div><h1>Ayleen H.</h1><h3>Migrationshintergrund</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Die Lage für Geflüchtete ist besonders schwierig, da die Zustände in den
                            Geflüchtetenunterkünften besonders heikel sind. Unter anderem auf Grund der nicht
                            vorhandenen Privatsphäre, des Zusammenlebens auf engstem Raum und des unklaren
                            Aufenthaltsstatus entstehen schnell stressige Situationen, die in körperlichen
                            Auseinandersetzungen oder sexualisierter Gewalt enden können.
                        </p>
                        <p>
                            Das Gesetz sieht es zudem vor, dass Geflüchtete in dem Bundesland bleiben müssen, dem sie
                            zugewiesen wurden. Das ist besonders für Betroffene von Partnerschaftsgewalt sehr schwierig,
                            da es entweder kaum Beratungsstellen oder Frauenhäuser gibt oder in diesen kein Platz
                            vorhanden ist. Außerdem besteht ein hohes Risiko, dass die Betroffene dort von ihrem Partner
                            oder Expartner aufgesucht wird.
                            Eine Umverteilung in ein anderes Bundesland würde ein Auffinden erschweren und der
                            Betroffenen mehr Sicherheit geben, ist aber sehr schwer durchzusetzen.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Berichterstattung */}
                <Modal
                    visible={this.state.visible[18]}
                    title={<div><h1>Birgit M.</h1><h3>Berichterstattung in den Medien</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            In den Medien wird Partnerschaftsgewalt mit Todesfolge oft als „Familiendrama“ bezeichnet, wenn es sich um deutsch gelesene Personen handelt.
                            Wird der Täter als migrantisch gelesen, wird von Mord gesprochen
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Befangenheit der Richter*innen */}
                <Modal
                    visible={this.state.visible[25]}
                    title={<div><h1>Ayleen H.</h1><h3>Befangenheit der Richter*innen</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Richter*innen agieren nicht immer so neutral, wie es ihr Beruf fordert. So gibt es einige
                            Richter*innen, die rassistisch, islamophob, antisemitisch oder antifeministisch sind.
                        </p>
                        <p>
                            Frauen mit Migrationshintergrund sind daher vor Gericht besonders starkem Misstrauen
                            ausgesetzt.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Fälle Stalking */}
                <Modal
                    visible={this.state.visible[26]}
                    title={<div><h1>Laura B.</h1><h3>Anzahl der Fälle von Stalking in 2019</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            18.905
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Bewertung der Motive */}
                <Modal
                    visible={this.state.visible[24]}
                    title={<div><h1>Birgit M.</h1><h3>Bewertung der Motive</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Bringt der Partner seine Partnerin um, wird das Tötungsmotiv oft nicht als niedrig gewertet,
                            denn er könne eifersüchtig gewesen sein. Hierzu gab es am 01.03.2012 ein Urteil des
                            Bundesgerichtshofes mit folgender Begründung:
                        </p>
                        <p>
                            „Der Angeklagte habe mithin begründeten Anlass zur Eifersucht gehabt, verbunden mit der
                            Erkenntnis und der Angst, nunmehr endgültig von seiner Ehefrau verlassen zu werden, sei
                            aber auch enttäuscht und verzweifelt über das unwiderrufliche Ende seiner bisherigen Lebens-
                            und Familienverhältnisse […] gewesen.“
                        </p>
                        <p>
                            Auch Heimtücke wird oft nicht als Motiv gewertet, da in Fällen von vorangegangener Gewalt
                            die Betroffene stets damit rechnen müsse, dass ihr Partner sie erneut angreifen könnte.
                            Die Frau könne daher nicht arglos sein.
                        </p>
                        <p>
                            Bringt eine Frau ihren Partner um, nachdem er sie misshandelt hat, so gilt diese Tat
                            hingegen oft als heimtückisch. Denn der Mann könne nicht damit rechnen, dass die Frau, die
                            die Gewalt vorher ertragen hat, sich plötzlich dazu entschließen könnte, sich zu wehren.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Marie E. */}
                <Modal
                    visible={this.state.visible[23]}
                    title={<div><h1>Maria E.</h1></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>Vergewaltigung durch den Partner</p>
                    </SimpleBar>
                </Modal>

                {/* Statistik von Partner getötete Frauen */}
                <Modal
                    visible={this.state.visible[22]}
                    title={<div><h1>Birgit M.</h1><h3>Anzahl der von ihrem Partner oder Expartner getöteten Frauen in 2019: </h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            117
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Tötungsmotive */}
                <Modal
                    visible={this.state.visible[21]}
                    title={<div><h1>Birgit M.</h1><h3>Tötungsmotive</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <p>
                            Heimtücke:
                            Die Arg- und Wehrlosigkeit des Opfers werden ausgenutzt.
                        </p>
                        <p>
                            Niedriger Beweggrund:
                            Die Motive sind als besonders verwerflich und verachtenswert zu betrachten.
                        </p>
                    </SimpleBar>
                </Modal>

                {/* Paragraph 223 */}
                <Modal
                    visible={this.state.visible[20]}
                    title={<div><h1>Ayleen H.</h1><h3>§ 223 StGB Körperverletzung</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <dl>
                            <dt>(1)</dt>
                            <dd>Wer eine andere Person misshandelt oder an der Gesundheit schädigt, wird mit
                                Freiheitsstrafe bis zu fünf Jahren oder mit Geldstrafe bestraft.
                            </dd>
                            <dt>(2)</dt>
                            <dd>Der Versuch ist strafbar.</dd>
                        </dl>
                    </SimpleBar>
                </Modal>

                {/* Paragraph 177 */}
                <Modal
                    visible={this.state.visible[29]}
                    title={<div><h1>Maria E.</h1><h3>§177 StGB Sexueller Übergriff; sexuelle Nötigung; Vergewaltigung</h3></div>}
                    mask={false}
                    onCancel={this.clearModal}
                    onOk={this.clearModal}
                    footer={null}
                    style={{top: '70%'}}
                    width={"50%"}
                    transitionName={""}
                    maskTransitionName={""}
                >
                    <SimpleBar style={{ maxHeight: modalHeight}}>
                        <dl>
                            <dt>(1)</dt>
                            <dd>Wer gegen den erkennbaren Willen einer anderen Person sexuelle Handlungen an
                                dieser Person vornimmt oder von ihr vornehmen lässt oder diese Person zur Vornahme
                                oder Duldung sexueller Handlungen an oder von einem Dritten bestimmt, wird mit
                                Freiheitsstrafe von sechs Monaten bis zu fünf Jahren bestraft.
                            </dd>
                            <dt>(2)</dt>
                            <dd>Ebenso wird bestraft, wer sexuelle Handlungen an einer anderen Person vornimmt
                                oder von ihr vornehmen lässt oder diese Person zur Vornahme oder Duldung sexueller
                                Handlungen an oder von einem Dritten bestimmt, wenn
                                <ol>
                                    <li>
                                        der Täter ausnutzt, dass die Person nicht in der Lage ist, einen
                                        entgegenstehenden Willen zu bilden oder zu äußern,
                                    </li>
                                    <li>
                                        der Täter ausnutzt, dass die Person auf Grund ihres körperlichen oder
                                        psychischen Zustands in der Bildung oder Äußerung des Willens erheblich
                                        eingeschränkt ist, es sei denn, er hat sich der Zustimmung dieser Person
                                        versichert,
                                    </li>
                                    <li>der Täter ein Überraschungsmoment ausnutzt,</li>
                                    <li>
                                        der Täter eine Lage ausnutzt, in der dem Opfer bei Widerstand ein
                                        empfindliches Übel droht, oder
                                    </li>
                                    <li>
                                        der Täter die Person zur Vornahme oder Duldung der sexuellen Handlung durch
                                        Drohung mit einem empfindlichen Übel genötigt hat.
                                    </li>
                                </ol>
                            </dd>
                            <dt>(3)</dt>
                            <dd>Der Versuch ist strafbar.</dd>
                            <dt>(4)</dt>
                            <dd>
                                Auf Freiheitsstrafe nicht unter einem Jahr ist zu erkennen, wenn die Unfähigkeit,
                                einen Willen zu bilden oder zu äußern, auf einer Krankheit oder Behinderung des
                                Opfers beruht
                            </dd>
                            <dt>(5)</dt>
                            <dd>
                                Auf Freiheitsstrafe nicht unter einem Jahr ist zu erkennen, wenn der Täter
                                <ol>
                                    <li>gegenüber dem Opfer Gewalt anwendet,</li>
                                    <li>dem Opfer mit gegenwärtiger Gefahr für Leib oder Leben droht oder</li>
                                    <li>
                                        eine Lage ausnutzt, in der das Opfer der Einwirkung des Täters schutzlos
                                        ausgeliefert ist.
                                    </li>
                                </ol>
                            </dd>
                            <dt>(6)</dt>
                            <dd>
                                In besonders schweren Fällen ist auf Freiheitsstrafe nicht unter zwei Jahren zu
                                erkennen. Ein besonders schwerer Fall liegt in der Regel vor, wenn
                                <ol>
                                    <li>
                                        der Täter mit dem Opfer den Beischlaf vollzieht oder vollziehen lässt oder
                                        ähnliche sexuelle Handlungen an dem Opfer vornimmt oder von ihm vornehmen
                                        lässt, die dieses besonders erniedrigen, insbesondere wenn sie mit einem
                                        Eindringen in den Körper verbunden sind (Vergewaltigung), oder
                                    </li>
                                    <li>
                                        die Tat von mehreren gemeinschaftlich begangen wird.
                                    </li>
                                </ol>
                            </dd>
                            <dt>(7)</dt>
                            <dd>
                                Auf Freiheitsstrafe nicht unter drei Jahren ist zu erkennen, wenn der Täter
                                <ol>
                                    <li>eine Waffe oder ein anderes gefährliches Werkzeug bei sich führt,</li>
                                    <li>
                                        sonst ein Werkzeug oder Mittel bei sich führt, um den Widerstand einer
                                        anderen Person durch Gewalt oder Drohung mit Gewalt zu verhindern oder zu
                                        überwinden, oder
                                    </li>
                                    <li>das Opfer in die Gefahr einer schweren Gesundheitsschädigung bringt.</li>
                                </ol>
                            </dd>
                            <dt>(8)</dt>
                            <dd>
                                Auf Freiheitsstrafe nicht unter fünf Jahren ist zu erkennen, wenn der Täter
                                <ol>
                                    <li>
                                        bei der Tat eine Waffe oder ein anderes gefährliches Werkzeug verwendet oder
                                    </li>
                                    <li>
                                        das Opfer
                                        <dl>
                                            <dt>a)</dt>
                                            <dd>bei der Tat körperlich schwer misshandelt oder</dd>
                                            <dt>b)</dt>
                                            <dd>durch die Tat in die Gefahr des Todes bringt.</dd>
                                        </dl>
                                    </li>
                                </ol>
                            </dd>
                            <dt>(9)</dt>
                            <dd>
                                In minder schweren Fällen der Absätze 1 und 2 ist auf Freiheitsstrafe von drei
                                Monaten bis zu drei Jahren, in minder schweren Fällen der Absätze 4 und 5 ist auf
                                Freiheitsstrafe von sechs Monaten bis zu zehn Jahren, in minder schweren Fällen der
                                Absätze 7 und 8 ist auf Freiheitsstrafe von einem Jahr bis zu zehn Jahren zu
                                erkennen.
                            </dd>
                        </dl>
                    </SimpleBar>
                </Modal>
            </div>
        );
    }
}

export default Content;