import React from 'react';
import {Button} from "antd";



class CustomButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Button type={"primary"}
                    shape={"circle"}
                    size={"small"}
                    className={this.props.className}
                    onClick={this.props.onClick}
                    style={ {color: '#ffffff', visibility: this.props.visibility} }
            >{'\u200C'}</Button>
        )
    }
}

export default CustomButton;