import React from 'react';
import './Information.less'
import {Typography} from "antd";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const { Link } = Typography;

const Imprint = () => (
    <div className={"title"}>
        <h2 style={{color: 'black'}}>Impressum</h2>
            <SimpleBar style={{ height: '90%' }}>
                <p>
                    Angaben gemäß § 5 TMG<br />
                    Alexandra Rauschgold<br />
                    Frerichsstraße 10<br />
                    24116 Kiel<br />
                </p>
                <h3>Kontakt</h3>
                <p>thispersondoesexist.projekt[at]gmail.com</p>
                <h2>Haftungsausschluss</h2>
                <h3>Haftung für Inhalte</h3>
                <p>
                    Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
                    Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden. Als Diensteanbietende sind wir gemäß
                    § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§
                    8 bis 10 TMG sind wir als Diensteanbietende jedoch nicht verpflichtet, übermittelte oder gespeicherte
                    fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                    allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                    Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>
                <h3>Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten Seiten ist stets der/die jeweilige Anbieter*in oder Betreiber*in der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                    Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                    zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>
                <h3>Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreibenden erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des/der jeweiligen
                    Autor*in bzw. Ersteller*in. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                    kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht von den Betreibenden
                    erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als
                    solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
                    wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Inhalte umgehend entfernen.
                </p>
                <h3>Datenschutz</h3>
                <p>
                    Die Nutzung dieser Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf den
                    Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden,
                    erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
                    Zustimmung nicht an Dritte weitergegeben.
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                    Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                    ausdrücklich widersprochen. Die Betreibenden der Seiten behalten sich ausdrücklich rechtliche Schritte
                    im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </p>
                <p>
                    Impressum vom <Link href={"https://www.impressum-generator.de/"}>Impressum Generator</Link> der
                    <Link href={"https://www.kanzlei-hasselbach.de/"}>Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht</Link>
                </p>
            </SimpleBar>
    </div>
)

export default Imprint;